<template>
<div>
    <b-row>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i><span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA EL ÁREA DE FODA</span>
                    <hr>
                    <CChartPie :options="optionsEstadistica" class="my-2" :datasets="dataSetsEstadistica" :labels="labelEstadistica" />
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog fa-md"></i> <span class="h5"> Gestión de Foda</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col   v-if="checkPermissions('003-RIE-FOD','c') == 1" cols="4" class="my-2">
                            <b-button block variant="custom" @click="modalRegistrarFoda = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br> Nuevo FODA
                            </b-button>
                        </b-col>

                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i><span class="h5"> Fodas registrados</span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col lg="2">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col lg="7">
                        </b-col>
                        <b-col lg="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                       <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table :items="listaFodas" :fields="campoFodas" bordered :per-page="porPagina" :current-page="currentPage" @filtered="onFiltered" :filter="filter" :filter-included-fields="filterOn" show-empty mediun responsive outlined fixed hover empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template #cell(opciones)="param">
                                    <b-button  v-if="checkPermissions('003-RIE-FOD','u') == 1"  :to="{name: 'Estrategias FODA', params: {id: param.item.idFoda}}" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                       <i class="fas fa-search my-0 mx-0"></i>
                                    </b-button>
                                    <b-button  v-if="checkPermissions('003-RIE-FOD','d') == 1" @click="eliminarFoda(param)" size="sm" variant="danger" class="mr-1 mb-1" v-c-tooltip="'Eliminar'">
                                       <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                    </b-button>
                                </template>
                                <template #cell(conseguido)="data">
                                    <b-icon variant="success" v-if="data.value=='si'" icon="check-circle-fill"></b-icon>
                                    <b-icon variant="danger" v-else icon="x-circle-fill"></b-icon>
                                </template>
                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>

        <CModal :closeOnBackdrop="false" size="xl" color="primario" :show.sync="modalRegistrarFoda">
            <template #header>
                <h6 class="modal-title">
                    <i class="fas fa-plus-square fa-md"></i>
                    <span class="h5"> Nuevo Foda </span>
                </h6>
                <CButtonClose @click="modalRegistrarFoda = false" class="text-white" />
            </template>

            <validation-observer ref="observer2" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(registrarFoda)">
                    <b-row>
                        <b-col md="3">
                            <validation-provider name="fecha" :rules="{required: true}" v-slot="validationContext">
                                <b-form-group label="Fecha:" class="mb-2">
                                    <b-form-input type="date" :state="getValidationState(validationContext)" v-model="datosNuevoFoda.fecha"></b-form-input>
                                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                </b-form-group>
                            </validation-provider>
                        </b-col>
                    </b-row>
                    <div class="py-2 px-2">
                        <b-row>
                            <b-col md="3" class="text-center">
                                <validation-provider name="fortalezas" :rules="{required: true}" v-slot="validationContext">
                                    <b-form-group label="Fortalezas:" class="mb-2">
                                        <b-input-group v-for="(input,k) in datosNuevoFoda.fortalezas" :key="k" class="mb-1">
                                            <b-form-textarea rows="3" max-rows="6" placeholder="Ingrese fortalezas" :state="getValidationState(validationContext)" v-model="input.descripcion"></b-form-textarea>
                                        </b-input-group>
                                    </b-form-group>
                                </validation-provider>
                                <CButton @click="agregarFortaleza()" class="mr-1" size="sm" color="dark">
                                    <i class="fas fa-plus fa-sm"></i> Añadir
                                </CButton>
                                <b-button @click="quitarFortaleza()" v-show="k || ( !k && datosNuevoFoda.fortalezas.length > 1)" size="sm" variant="danger" v-c-tooltip="'Quitar'">
                                   <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                </b-button>
                            </b-col>
                            <b-col md="3" class="text-center">
                                <validation-provider name="oportunidades" :rules="{required: true}" v-slot="validationContext">
                                    <b-form-group label="Oportunidades:" class="mb-2">
                                        <b-input-group v-for="(input,k) in datosNuevoFoda.oportunidades" :key="k" class="mb-1">
                                            <b-form-textarea rows="3" max-rows="6" placeholder="Ingrese oportunidades" :state="getValidationState(validationContext)" v-model="input.descripcion"></b-form-textarea>
                                        </b-input-group>
                                    </b-form-group>
                                </validation-provider>
                                <CButton @click="agregarOportunidad()" class="mr-1" size="sm" color="dark">
                                    <i class="fas fa-plus fa-sm"></i> Añadir
                                </CButton>
                                <b-button @click="quitarOportunidad()" v-show="k || ( !k && datosNuevoFoda.oportunidades.length > 1)" size="sm" variant="danger" v-c-tooltip="'Quitar'">
                                   <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                </b-button>
                            </b-col>
                            <b-col md="3" class="text-center">
                                <validation-provider name="debilidades" :rules="{required: true}" v-slot="validationContext">
                                    <b-form-group label="Debilidades:" class="mb-2">
                                        <b-input-group v-for="(input,k) in datosNuevoFoda.debilidades" :key="k" class="mb-1">
                                            <b-form-textarea rows="3" max-rows="6" placeholder="Ingrese debilidades" :state="getValidationState(validationContext)" v-model="input.descripcion"></b-form-textarea>
                                        </b-input-group>
                                    </b-form-group>
                                </validation-provider>
                                <CButton @click="agregarDebilidad()" class="mr-1" size="sm" color="dark">
                                    <i class="fas fa-plus fa-sm"></i> Añadir
                                </CButton>
                                <b-button @click="quitarDebilidad()" v-show="k || ( !k && datosNuevoFoda.debilidades.length > 1)" size="sm" variant="danger" v-c-tooltip="'Quitar'">
                                   <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                </b-button>
                            </b-col>
                            <b-col md="3" class="text-center">
                                <validation-provider name="amenazas" :rules="{required: true}" v-slot="validationContext">
                                    <b-form-group label="Amenazas:" class="mb-2">
                                        <b-input-group v-for="(input,k) in datosNuevoFoda.amenazas" :key="k" class="mb-1">
                                            <b-form-textarea rows="3" max-rows="6" placeholder="Ingrese amenenazas" :state="getValidationState(validationContext)" v-model="input.descripcion"></b-form-textarea>
                                        </b-input-group>
                                    </b-form-group>
                                </validation-provider>
                                <CButton @click="agregarAmenaza()" class="mr-1" size="sm" color="dark">
                                    <i class="fas fa-plus fa-sm"></i> Añadir
                                </CButton>
                                <b-button @click="quitarAmenaza()" v-show="k || ( !k && datosNuevoFoda.amenazas.length > 1)" size="sm" variant="danger" v-c-tooltip="'Quitar'">
                                   <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                </b-button>
                            </b-col>
                        </b-row>
                    </div>

                    <b-col class="my-2 text-right" md="12">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalRegistrarFoda = false">
                            Cancelar
                        </b-button>
                    </b-col>

                </b-form>
            </validation-observer>

            <template #footer>
                <span></span>
            </template>
        </CModal>
    </b-row>
</div>
</template>

<script>
import {
    CChartPie
} from '@coreui/vue-chartjs'
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {
    components: {
        CChartPie
    },
    data() {
        return {
            labelEstadistica: ["Fortalezas", "Oportunidades", "Debilidades", "Amenazas"],
            optionsEstadistica: {
                legend: {
                    labels: {
                        fontSize: 15,
                    },
                    position: 'left',
                    align: 'center'
                },
            },
            k: 0,
            modalRegistrarFoda: false,
            totalRows: 0,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],
            campoFodas: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                }, {
                    key: "fecha",
                    label: "Fecha",
                    class: "text-center",
                },
                {
                    key: "fortalezas",
                    label: "Fortalezas",
                    class: "text-center",
                },
                {
                    key: "oportunidades",
                    label: "Oportunidades",
                    class: "text-center",
                },
                {
                    key: "debilidades",
                    label: "Debilidades",
                    class: "text-center",
                },
                {
                    key: "amenazas",
                    label: "Amenazas",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center"
                }
            ],
            listaFodas: [],
            datosNuevoFoda: {
                fecha: moment().format('YYYY-MM-DD'),
                fortalezas: [{
                    idFodaDetalle: '',
                    identificador: 'F',
                    descripcion: ''
                }],
                oportunidades: [{
                    idFodaDetalle: '',
                    identificador: 'O',
                    descripcion: ''
                }],
                debilidades: [{
                    idFodaDetalle: '',
                    identificador: 'D',
                    descripcion: ''
                }],
                amenazas: [{
                    idFodaDetalle: '',
                    identificador: 'A',
                    descripcion: ''
                }],
            },
            disabled: false,
            datosSession: {
                idCliente: ''
            },
            datosEstadistica: {
                fortalezas: 0,
                oportunidades: 0,
                debilidades: 0,
                amenazas: 0
            }

        }
    },
    methods: {
        checkPermissions(code, crud) {
            return this.$store.state.permissions.filter(x => x.codigo == code)[0][crud]
        },
        agregarFortaleza() {
            this.datosNuevoFoda.fortalezas.push({
                idFodaDetalle: '',
                identificador: 'F',
                descripcion: ''
            })

        },
        agregarOportunidad() {
            this.datosNuevoFoda.oportunidades.push({
                idFodaDetalle: '',
                identificador: 'O',
                descripcion: ''
            })
        },
        agregarDebilidad() {
            this.datosNuevoFoda.debilidades.push({
                idFodaDetalle: '',
                identificador: 'D',
                descripcion: ''
            })
        },
        agregarAmenaza() {
            this.datosNuevoFoda.amenazas.push({
                idFodaDetalle: '',
                identificador: 'A',
                descripcion: ''
            })
        },
        quitarFortaleza() {
            this.datosNuevoFoda.fortalezas.pop()
        },
        quitarOportunidad() {
            this.datosNuevoFoda.oportunidades.pop()
        },
        quitarDebilidad() {
            this.datosNuevoFoda.debilidades.pop()
        },
        quitarAmenaza() {
            this.datosNuevoFoda.amenazas.pop()
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length
            this.currentPage = 1
        },
        listarFodas() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/listar-fodas", {
                    params: {
                        idCliente: me.datosSession.idCliente,
                    }
                })
                .then(function (response) {
                    me.listaFodas = response.data;
                    me.totalRows = me.listaFodas.length;
                    me.datosEstadistica.fortalezas = 0;
                    me.datosEstadistica.oportunidades = 0;
                    me.datosEstadistica.debilidades = 0;
                    me.datosEstadistica.amenazas = 0;
                    for (const i in response.data) {
                        me.datosEstadistica.fortalezas += parseInt(response.data[i].fortalezas);
                        me.datosEstadistica.oportunidades += parseInt(response.data[i].oportunidades);
                        me.datosEstadistica.debilidades += parseInt(response.data[i].debilidades);
                        me.datosEstadistica.amenazas += parseInt(response.data[i].amenazas);
                    }
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        registrarFoda() {
            let me = this;
            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/registrar-foda", {
                        datosFoda: me.datosNuevoFoda,
                        idCliente: me.datosSession.idCliente,
                    }, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje);
                    me.listarFodas();
                    me.modalRegistrarFoda = false;
                    me.disabled = false;
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                });
        },
        resetModalNuevoFoda() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
            this.datosNuevoFoda.fecha = moment().format('YYYY-MM-DD'),
                this.datosNuevoFoda.fortalezas.length = 0;
            this.datosNuevoFoda.oportunidades.length = 0;
            this.datosNuevoFoda.debilidades.length = 0;
            this.datosNuevoFoda.amenazas.length = 0;

            this.datosNuevoFoda.fortalezas.push({
                idFodaDetalle: '',
                identificador: 'F',
                descripcion: ''
            })
            this.datosNuevoFoda.oportunidades.push({
                idFodaDetalle: '',
                identificador: 'O',
                descripcion: ''
            })
            this.datosNuevoFoda.debilidades.push({
                idFodaDetalle: '',
                identificador: 'D',
                descripcion: ''
            })
            this.datosNuevoFoda.amenazas.push({
                idFodaDetalle: '',
                identificador: 'A',
                descripcion: ''
            })
        },
        eliminarFoda(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el FODA?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    axios
                        .post(CONSTANTES.URL_RUTA_SERVICIOS + "coordinador/eliminar-foda", {
                            idFoda: param.item.idFoda,
                        }, {
                            headers: {
                                Authorization: `Bearer ${localStorage.token}`,
                            }
                        })
                        .then(function (response) {
                            let color = response.data.resultado == 1 ? "success" : "error";
                            me.swat(color, response.data.mensaje)
                            me.listarFodas();
                        })
                        .catch(function (error) {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        }
    },
    computed: {
        dataSetsEstadistica() {
            return [{
                borderWidth: 3,
                backgroundColor: [
                    '#6bba70db',
                    '#428bca94',
                    '#fdcd60cc',
                    '#f16553d4',

                ],
                data: [this.datosEstadistica.fortalezas, this.datosEstadistica.oportunidades, this.datosEstadistica.debilidades, this.datosEstadistica.amenazas]
            }]
        }
    },
    watch: {
        modalRegistrarFoda: function (val) {
            if (val == false) {
                this.resetModalNuevoFoda();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosSession.idCliente = user.uidClient;
            this.listarFodas();
        }
    }

}
</script>
